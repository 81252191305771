<script>
import EventBus from '@/js/utils/EventBus'
import propsModalId from '@/js/utils/propsModalId'

// Note: One would think that this component being a child of ModalContainer
// would be called ModalContainerButton but because this component will be
// used outside of ModalContainer as a sibling/or more it breaks out of the
// tightly coupled naming convention that vue talks about in their style guide.

export default {
  props: {
    tag: {
      type: String,
      default: 'button',
    },

    // required prop for matching the ModalButton
    // annd ModalContainer
    ...propsModalId,
  },

  methods: {
    handleClickEvent() {
      EventBus.$emit('toggle:modal', this.modalId)
    },
  },
  render(createElement) {
    const elementData = {
      on: {
        click: this.handleClickEvent,
      },
    }
    return createElement(this.tag, elementData, this.$slots.default)
  },
}
</script>
