<script>
import accordion from '@/js/utils/accordion'

import AccordionContainerTransition from '@/js/components/AccordionContainerTransition'

export default {
  inject: { accordion },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    id: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: 'show',
      validator: mode => ['if', 'show'].includes(mode),
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
    }
  },
  computed: {
    button() {
      return this.accordion.buttons.find(this.isLabelledbyButton)
    },
    role() {
      const accordionVm = this.accordion

      if (accordionVm.multiple && accordionVm.panels.length > 6) {
        return null
      }

      return 'region'
    },
    ariaLabelledby() {
      return this.button ? this.button.id : null
    },
  },
  created() {
    this.$on('toggle', this.toggle)

    this.accordion.$emit('create:panel', this)
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
    },
    handleKeydown(event) {
      if (!event.ctrlKey) {
        return
      }

      const eventToEmit = {
        PageUp: 'focus:header.current',
        PageDown: 'focus:header.next',
      }[event.key]

      if (!eventToEmit) {
        return
      }

      event.preventDefault()

      this.accordion.$emit(eventToEmit)
    },
    isLabelledbyButton({ controls }) {
      return this.id === controls
    },
    createChild(createElement, elementData) {
      if (!(this.mode === 'show' || this.expanded)) {
        return undefined
      }
      return createElement(AccordionContainerTransition, [
        createElement(this.tag, elementData, this.$slots.default),
      ])
    },
  },
  render(createElement) {
    const { id, role, expanded, ariaLabelledby } = this
    const elementData = {
      attrs: {
        id,
        role,
        // bad
        // 'aria-hidden': expanded.toString(),
        'aria-labelledby': ariaLabelledby,
      },
      class: { expanded },
      props: {
        expanded,
        tag: this.tag,
        options: {
          duration: 0.4,
        },
      },
      on: { keydown: this.handleKeydown },
    }

    if (this.mode === 'show') {
      elementData.directives = [
        {
          name: 'show',
          value: expanded,
        },
      ]
    }

    return this.createChild(createElement, elementData)
  },
}
</script>
