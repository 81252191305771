<template>
  <transition :css="false" @enter="enter" @leave="leave">
    <slot />
  </transition>
</template>

<script>
import { TweenLite, Power4 } from 'gsap'

const duration = 0.5

export default {
  methods: {
    enter(el, done) {
      TweenLite.set(el, {
        height: 'auto',
        overflow: 'hidden',
      })
      TweenLite.from(el, duration, {
        height: '0',
        ease: Power4.easeOut,
        onComplete: done,
      })
    },
    leave(el, done) {
      TweenLite.set(el, { overflow: 'hidden' })
      TweenLite.to(el, duration, {
        height: 0,
        ease: Power4.easeOut,
        onComplete: done,
      })
    },
  },
}
</script>
