import Hammer from 'hammerjs'

import carousel from '@/js/utils/carousel'

import CarouselContainerAnimation from '@/js/components/CarouselContainerAnimation'

export default {
  inject: { carousel },

  props: {
    animationType: {
      type: String,
      default: 'fadeInLeft',
    },
  },

  data: () => ({
    defaultClass: undefined,
  }),

  mounted() {
    ;[this.defaultClass] = this.$el.classList

    const hammer = new Hammer(this.$el)

    hammer.on('swipeleft', () => this.setCarouselDirection('next'))
    hammer.on('swiperight', () => this.setCarouselDirection('previous'))
  },

  created() {
    this.emitEvent('create:items', this)
  },

  computed: {
    evaluateItem() {
      return this.carousel.currentItem === this
    },
  },

  methods: {
    emitEvent(...events) {
      this.carousel.$emit(...events)
    },

    handleEvent() {
      this.emitEvent('set:item', this)
    },

    setCarouselDirection(direction) {
      this.emitEvent('carousel:direction', direction)
    },
  },

  render(createElement) {
    const { evaluateItem } = this
    const elementData = {
      attrs: {
        'aria-hidden': (!evaluateItem).toString(),
      },
      directives: [
        {
          name: 'show',
          value: evaluateItem,
        },
      ],
      class: {
        [`${this.defaultClass}--isActive`]: evaluateItem,
      },
    }

    const createsChildElement = createElement(
      CarouselContainerAnimation,
      {
        props: { animationType: this.animationType },
        key: `BaseCarouselListItem_${this.carousel.currentItemIndex}`,
      },
      this.$slots.default
    )

    return createElement('li', elementData, [createsChildElement])
  },
}
