import carousel from '@/js/utils/carousel'
import propsTag from '@/js/utils/propsTag'

export default {
  provide() {
    return { [carousel]: this }
  },
  props: {
    ...propsTag,
  },

  data: () => ({
    items: [],
    currentItem: undefined,
  }),

  created() {
    // carousel direction has callback which
    // is a string prop either previous or next
    this.$on('carousel:direction', direction => this[`${direction}Item`]())

    // set item
    this.$on('set:item', this.setItem)
    // creates items
    this.$on('create:items', this.createItems)
  },

  computed: {
    currentItemIndex() {
      return this.items.indexOf(this.currentItem)
    },
  },

  methods: {
    setItem(item) {
      this.currentItem = item
    },

    createItems(item) {
      this.items.push(item)

      if (!this.currentItem) {
        this.setItem(item)
      }
    },

    nextItem() {
      this.carouselDirection(this.currentItemIndex + 1)
    },
    previousItem() {
      this.carouselDirection(this.currentItemIndex - 1)
    },

    carouselDirection(direction) {
      const { items } = this
      const { length } = items
      const currentItem = items[((direction % length) + length) % length]

      this.setItem(currentItem)
    },
  },

  render(createElement) {
    return createElement(this.tag, this.$slots.default)
  },
}
