<template>
  <transition :css="false" @enter="enter" @leave="leave">
    <slot />
  </transition>
</template>

<script>
import { TweenLite, Power4 } from 'gsap'

export default {
  methods: {
    enter(el, done) {
      TweenLite.fromTo(
        el,
        0.25,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          ease: Power4.easeOut,
        }
      )
      TweenLite.fromTo(
        el.children[0],
        0.25,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          ease: Power4.easeOut,
          delay: 0.15,
          onComplete: done,
        }
      )
    },
    leave(el, done) {
      TweenLite.to(el, 0.25, {
        autoAlpha: 0,
        onComplete: done,
        ease: Power4.easeOut,
      })
    },
  },
}
</script>
