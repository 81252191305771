<script>
import EventBus from '@/js/utils/EventBus'

import DisclosureTransition from '@/js/components/DisclosureTransition.vue'
import AccordionContainerTransition from '@/js/components/AccordionContainerTransition.vue'

export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    disclosureId: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: 'show',
      validate: value => ['if', 'show'].includes(value),
    },
    activeAnimation: {
      type: String,
      default: 'DisclosureTransition',
    },
  },
  data() {
    return {
      expanded: undefined,
      transitionComponent: {
        DisclosureTransition,
        AccordionContainerTransition,
      }[this.activeAnimation],
    }
  },
  created() {
    EventBus.$on('toggle', this.toggle)
    EventBus.$emit('created:container', this.disclosureId)
  },
  methods: {
    toggle({ controls, expanded }) {
      if (controls === this.disclosureId) {
        this.expanded = expanded
      }
    },
    createEl(h, data) {
      if (!(this.mode === 'show' || this.expanded)) {
        return undefined
      }

      return h(this.transitionComponent, [
        h(this.tag, data, this.$slots.default),
      ])
    },
  },
  render(h) {
    const { expanded } = this
    const data = {
      attrs: {
        id: this.disclosureId,
      },
      class: { expanded },
    }

    if (this.mode === 'show') {
      data.directives = [
        {
          name: 'show',
          value: expanded,
        },
      ]
    }

    return this.createEl(h, data)
  },
}
</script>
