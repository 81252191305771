<script>
import { TweenLite, Back } from 'gsap'

import propsTag from '@/js/utils/propsTag'

export default {
  name: 'ModalContainerDialog',
  inject: ['modal'],
  props: {
    ...propsTag,
    animationType: {
      type: String,
      requred: true,
    },
  },

  watch: {
    'modal.expanded': 'registerAnimation',
  },

  mounted() {
    this.translateTo()
  },

  created() {
    this.$emit('createsModalDialog', this)

    this.$on('focus-last-descendant', this.focusLastDescendant)
    this.$on('focus-first-descendant', this.focusFirstDescendant)
  },

  methods: {
    translateTo() {
      TweenLite.fromTo(
        this.$el,
        0.8,
        {
          y: '+=50',
          opacity: 0,
        },
        {
          y: '0',
          opacity: 1,
          ease: Back.easeOut,
        }
      )
    },

    slideOut() {
      TweenLite.from(this.$el, 0.8, {
        x: '100%',
      })
    },

    registerAnimation(expanded) {
      if (!expanded) {
        return
      }
      this[this.animationType]()
    },

    focusFirstDescendant(el = this.$el) {
      return Array.from(el.children).find(
        child => this.attemptFocus(child) || this.focusFirstDescendant(child)
      )
    },

    focusLastDescendant(el = this.$el) {
      return Array.from(el.children)
        .reverse()
        .find(
          child => this.attemptFocus(child) || this.focusLastDescendant(child)
        )
    },
    attemptFocus(el) {
      el.focus()

      return el === document.activeElement
    },
  },

  render(createElement) {
    const elementData = {
      attrs: {
        role: 'dialog',
        'aria-modal': 'true',
      },
    }

    return createElement(this.tag, elementData, this.$slots.default)
  },
}
</script>

<style>
.ModalContainerDialog {
  width: 100%;
  max-width: 800px;
  height: 400px;
  max-height: 90vh;
  background-color: #fff;
}
</style>
