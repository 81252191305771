<template>
  <button
    type="button"
    :aria-controls="disclosureId"
    :aria-expanded="expanded.toString()"
    @click="expanded = !expanded"
  >
    <slot />
  </button>
</template>

<script>
import EventBus from '@/js/utils/EventBus'

export default {
  props: {
    disclosureId: {
      type: String,
      required: true,
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
    }
  },
  created() {
    EventBus.$on('created:container', this.createdContainer)
  },
  watch: {
    expanded() {
      EventBus.$emit('toggle', {
        expanded: this.expanded,
        controls: this.disclosureId,
      })
    },
  },
  methods: {
    createdContainer(disclosureId) {
      EventBus.$emit('toggle', {
        expanded: this.expanded,
        controls: disclosureId,
      })
    },
  },
}
</script>
