import Vue from 'vue'

// Sass
import '@/styles/main.scss'

// Post CSS
import 'postcss-object-fit-images'

// Pollyfills
import svg4everybody from 'svg4everybody'
import objectFitImages from 'object-fit-images'

// Modal
import ModalButton from './components/ModalButton.vue'
import ModalContainer from './components/ModalContainer.vue'
import ModalContainerDialog from './components/ModalContainerDialog.vue'

// Carousel
import CarouselContainer from './components/CarouselContainer'
import CarouselContainerItem from './components/CarouselContainerItem'
import CarouselContainerButton from './components/CarouselContainerButton'
import CarouselContainerControl from './components/CarouselContainerControl'

// Disclosure
import DisclosureButton from './components/DisclosureButton.vue'
import DisclosureContent from './components/DisclosureContent.vue'

// Accordion
import AccordionContainer from './components/AccordionContainer'
import AccordionContainerPanel from './components/AccordionContainerPanel'
import AccordionContainerHeader from './components/AccordionContainerHeader'
import AccordionContainerHeaderButton from './components/AccordionContainerHeaderButton'

// Intersection Observer Polyfill
import 'intersection-observer'

// Animate on Scroll
import AOS from 'aos'

// Masonry
import Masonry from 'masonry-layout'

Vue.config.productionTip = false

svg4everybody()
objectFitImages()

AOS.init({
  offset: 300,
  duration: 500,
})

new Masonry('.events__container', {
  itemSelector: '.event',
  horizontalOrder: true,
  gutter: 30,
})

const Components = {
  ModalButton,
  ModalContainer,
  ModalContainerDialog,
  CarouselContainer,
  CarouselContainerItem,
  CarouselContainerButton,
  CarouselContainerControl,
  DisclosureButton,
  DisclosureContent,
  AccordionContainer,
  AccordionContainerPanel,
  AccordionContainerHeader,
  AccordionContainerHeaderButton,
}

Object.keys(Components).forEach(componentName => {
  Vue.component(componentName, Components[componentName])
})

new Vue().$mount('#app')
