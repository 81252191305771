import carousel from '@/js/utils/carousel'

export default {
  inject: { carousel },
  computed: {
    eavalutesCurrentIndex() {
      return this.carousel.currentItemIndex === this.$vnode.key
    },
  },
  methods: {
    handleButtonClick() {
      // for this component to work you have to bind the index to the
      // component's key property. :key="index"
      this.carousel.$emit('set:item', this.carousel.items[this.$vnode.key])
    },
  },

  render(createElement) {
    const elementData = {
      on: {
        click: this.handleButtonClick,
      },
      class: {
        active: this.eavalutesCurrentIndex,
      },
    }
    return createElement('button', elementData, this.$slots.default)
  },
}
