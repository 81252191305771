<script>
import EventBus from '@/js/utils/EventBus'
import propsTag from '@/js/utils/propsTag'
import propsModalId from '@/js/utils/propsModalId'

import ModalContainerDialog from '@/js/components/ModalContainerDialog.vue'

export default {
  provide() {
    return { modal: this }
  },
  props: {
    ...propsTag,
    ...propsModalId,

    mode: {
      type: String,
      default: 'show',
      validator: mode => ['if', 'show'].includes(mode),
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
    animationType: {
      type: String,
      default: 'translateTo',
    },
  },

  data() {
    return {
      expanded: false,
      modalDialog: undefined,
    }
  },

  created() {
    EventBus.$on('toggle:modal', this.maybeToggleModal)
  },
  mounted() {
    if (this.initiallyExpanded) {
      this.toggleModal()
    }
  },

  methods: {
    maybeToggleModal(buttonId) {
      if (buttonId !== this.modalId) {
        return
      }

      this.toggleModal()
    },

    toggleModal() {
      const { expanded } = this

      this.expanded = !expanded
      this.$emit(`modal-${expanded ? 'close' : 'open'}`)
    },

    createsFocusTrap(createElement, position) {
      return createElement('div', {
        attrs: { tabindex: 0 },
        on: {
          focus: () => this.modalDialog.$emit(`focus-${position}-descendant`),
        },
      })
    },

    createsModalButton(createElement) {
      const { modalButton } = this.$slots
      const elementData = {
        class: 'ModalButton--close',
        props: { modalId: this.modalId },
      }
      return modalButton
        ? createElement('ModalButton', elementData, modalButton)
        : undefined
    },

    createsModalDialog(createElement) {
      const modalDialog = createElement(
        ModalContainerDialog,
        {
          props: {
            animationType: this.animationType,
          },
          on: {
            createsModalDialog: dialog => {
              this.modalDialog = dialog
            },
          },
        },
        this.$slots.default
      )

      return [
        this.createsFocusTrap(createElement, 'first'),
        this.createsModalButton(createElement),
        modalDialog,
        this.createsFocusTrap(createElement, 'last'),
      ]
    },
  },

  render(createElement) {
    const elementData = {
      on: {
        keyup: ({ keyCode }) => {
          if (keyCode !== 27) {
            return
          }

          this.toggleModal()
        },
      },
    }

    const { expanded, mode } = this

    if (mode === 'show') {
      elementData.directives = [
        {
          name: 'show',
          value: expanded,
        },
      ]
    }

    const modalOverlay = createElement('div', {
      staticClass: 'ModalContainer__overlay',
      on: { click: () => EventBus.$emit('toggle:modal', this.modalId) },
    })
    const modalDialog = this.createsModalDialog(createElement)

    return mode === 'show' || expanded
      ? createElement(this.tag, elementData, [modalOverlay, modalDialog])
      : undefined
  },
}
</script>

<style lang="scss">
.ModalContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalContainer__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.ModalButton--close {
  $_button-position: 20px;

  position: fixed;
  top: $_button-position;
  right: $_button-position;
}
</style>
