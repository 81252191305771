import { TweenLite } from 'gsap'

export default {
  props: {
    animationType: {
      type: String,
      requried: true,
    },
  },

  mounted() {
    this[this.animationType]()
  },

  methods: {
    fadeInLeft() {
      TweenLite.fromTo(
        this.$el,
        1,
        {
          x: 20,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
        }
      )
    },
  },

  render(createElement) {
    return createElement('div', this.$slots.default)
  },
}
